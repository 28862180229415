import {bolt} from '@wix/bolt-viewer-manager-testkit/dist/versions.json'
import {loadScript} from '@wix/tb-main/src/utils'

if (!window.location.search.includes('BoltSource')) {
    window.boltBase = `https://static.parastorage.com/services/wix-bolt/${bolt}`
}

const main = `${window.boltBase}/bolt-main/app/main-r.min.js`

loadScript('https://static.parastorage.com/unpkg-semver/wix-recorder@^1/app.bundle.min.js', 'wix-recorder')
    .catch(e => console.log(e))

loadScript(main, 'bolt mainr')
    .then(() => {})
    .catch(e => console.log(e))


